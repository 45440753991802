import React from "react";
import {
  FaLinkedinIn,
  FaInstagram,
  FaTiktok,
  FaTwitterSquare,
  FaFacebookF,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-13 px-4 grid gap-8 text-gray-300">
      <div>
        <div
          className="flex  my-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div style={{ paddingRight: "1rem" }}>
            <FaInstagram size={30} />
          </div>
          <div style={{ paddingRight: "1rem" }}>
            <FaTwitterSquare size={30} />
          </div>
          <div style={{ paddingRight: "1rem" }}>
            <FaTiktok size={30} />
          </div>
          <div style={{ paddingRight: "1rem" }}>
            <FaLinkedinIn size={30} />
          </div>
          <div style={{ paddingRight: "1rem" }}>
            <FaFacebookF size={30} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
