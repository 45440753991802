import React, { useEffect, useRef, useState } from "react";
import Typed from "react-typed";
import LogoAhBoa from "../assets/ahboalogotraçado.png";
import "../index.css";
import { cleanup } from "@testing-library/react";

const Hero = () => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimeMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    const countDownDate = new Date("Jun 30, 2024 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        // parar nosso timer
        cleanup(interval.current);
      } else {
        //atualizar timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimeMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      cleanup(interval.current);
    };
  });

  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen-[134vh] mx-auto text-center flex flex-col justify-center">
        <img
          src={LogoAhBoa}
          className="w-[30em]"
          style={{ alignSelf: "center", marginBottom: "0px",   width: '60%', maxWidth: '100%', height: "auto" }}
        />
        <p className="md:text-5xl sm:text-4xl text-xl font-bold py-1">
          Em Breve ;)
        </p>
        <div className="flex justify-center items-center mb-[8px]">
          <Typed
            className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["Em todas as plataformas digitais"]}
            typeSpeed={140}
            backSpeed={120}
            loop
          />
        </div>
        <section className="timer-container">
          <section className="timer">
            <div>
              <span className="mdi mdi-calendar-clock">
                <p></p>
              </span>
            </div>
            <div>
              <section>
                <p>{timerDays < 10 ? "0" + timerDays : timerDays}</p>
                <p>
                  <small>Dias</small>
                </p>
              </section>
              <span>:</span>
              <section>
                <p>{timerHours < 10 ? "0" + timerHours : timerHours}</p>
                <p>
                  <small>Horas</small>
                </p>
              </section>
              <span>:</span>
              <section>
                <p>{timerMinutes < 10 ? "0" + timerMinutes : timerMinutes}</p>
                <p>
                  <small>Minutos</small>
                </p>
              </section>
              <span>:</span>
              <section>
                <p>{timerSeconds < 10 ? "0" + timerSeconds : timerSeconds}</p>
                <p>
                  <small>Segundos</small>
                </p>
              </section>
            </div>
          </section>
        </section>
        <div>
        <p className="md:text-2xl sm:text-2xl text-xl py-5 mt-0">
          Imagine ter todos os eventos, festas e bares mais badalados na palma{" "}
          <br />
          da sua mão. Com o AH BOA, você terá acesso a uma plataforma única,
          <br /> que conecta os produtores de eventos às pessoas, proporcionando{" "}
          <br /> uma jornada descomplicada na busca pelo seu próximo destino.{" "}
          <br />
          <br />
          <b>"Ah Boa é hoje e agora"</b>
        </p>

        </div>
        
      </div>
    </div>
  );
};

export default Hero;
